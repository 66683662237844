import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// export const getAllNotification = createAsyncThunk(
//   "auth/getAllNotification",
//   async (body) => {
//     try {
//       const currentUserString = localStorage.getItem("currentUser");
//       if (!currentUserString) {
//         throw new Error("No currentUser found in localStorage");
//       }

//       const currentUser = JSON.parse(currentUserString);
//       const token = currentUser.token;

//       if (!token) {
//         throw new Error("No token found in currentUser object");
//       }

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/admin/all-notifications`,
//         body,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("response in thunk", response);
//       return response.data?.notifications;
//     } catch (error) {
//       console.log("error in thunk", error);
//       // throw error.response.data.message;
//       throw error.response.data.message;
//     }
//   }
// );

// export const getAllNotificationForScroll = createAsyncThunk(
//   "auth/getAllNotificationForScroll",
//   async (body) => {
//     try {
//       const currentUserString = localStorage.getItem("currentUser");
//       if (!currentUserString) {
//         throw new Error("No currentUser found in localStorage");
//       }

//       const currentUser = JSON.parse(currentUserString);
//       const token = currentUser.token;

//       if (!token) {
//         throw new Error("No token found in currentUser object");
//       }

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/api/admin/all-notifications`,
//         body,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("response in thunk", response);
//       return response.data?.notifications;
//     } catch (error) {
//       console.log("error in thunk", error);
//       // throw error.response.data.message;
//       throw error.response.data.message;
//     }
//   }
// );

export const getAllNotification = createAsyncThunk(
  "auth/getAllNotification",
  async ({ page, perPage } = {}) => {
    let url = `${process.env.REACT_APP_BASE_URL}/api/admin/all-notifications`;
    if (page !== undefined) {
      url += `?page=${page}`;
      if (perPage !== undefined) {
        url += `&per_page=${perPage}`;
      }
    } else if (perPage !== undefined) {
      url += `?per_page=${perPage}`;
    }
    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const body = null;
      const response = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response in thunk", response);
      return response.data?.notifications;
    } catch (error) {
      console.log("error in thunk", error);
      // throw error.response.data.message;
      throw error.response.data.message;
    }
  }
);

export const getAllNotificationForScroll = createAsyncThunk(
  "auth/getAllNotificationForScroll",
  async ({ page, perPage } = {}) => {
    console.log("page, perPage", page, perPage);
    let url = `${process.env.REACT_APP_BASE_URL}/api/admin/all-notifications`;
    if (page !== undefined) {
      url += `?page=${page}`;
      if (perPage !== undefined) {
        url += `&per_page=${perPage}`;
      }
    } else if (perPage !== undefined) {
      url += `?per_page=${perPage}`;
    }

    try {
      const currentUserString = localStorage.getItem("currentUser");
      if (!currentUserString) {
        throw new Error("No currentUser found in localStorage");
      }

      const currentUser = JSON.parse(currentUserString);
      const token = currentUser.token;

      if (!token) {
        throw new Error("No token found in currentUser object");
      }

      const body = null;
      const response = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response in thunk", response);
      return response.data?.notifications;
    } catch (error) {
      console.log("error in thunk", error);
      throw error.response.data.message;
    }
  }
);
