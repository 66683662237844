import { Footer } from "antd/es/layout/layout";
import React from "react";
import { styled } from "styled-components";
import { Row } from "antd";
import { Link } from "react-router-dom";
import usecustomStyles from "../Common/customStyles";
import { themecolor } from "../config";

const customStyles = usecustomStyles();

const StyleFooter = styled(Footer)`
  padding-inline: 24px;
  z-index: 9;
  color: ${customStyles.colorTextDisabled};
  margin-left: ${themecolor.components.Menu.verticalSidebarWidth}px;
  background-color: ${({ theme }) => theme.token.colorBgContainer};
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.token.colorBorder};

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

const FooterLayout = () => {
  return (
    <StyleFooter id="antFooterLayout">
      <Row align="middle">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            whiteSpace: "nowrap",
            zIndex: "9",
            flexWrap: "wrap",
            columnGap: 2,
          }}
        >
          <div style={{ marginRight: "16px", marginBottom: "0" }}>
            2024 © NoeTalk-Admin.
          </div>
          <div style={{ marginBottom: "0" }}>
            {" "}
            Operated & Maintainde for{" "}
            <Link to="https://noe-talk.xyz/" target="_blank">
              NoeTalk
            </Link>
          </div>
        </div>
      </Row>
    </StyleFooter>
  );
};

export default FooterLayout;
