// import { useState, useMemo } from "react";
// import { useSelector } from "react-redux";
// import { Table, Tag, Select } from "antd";

// import { monthDayAndYear, onlyTime } from "../../Util/helperFunctions";
// import moment from "moment";

// const { Option } = Select;

// function AllNotification() {
//   const { allNotification } = useSelector((state) => state.Notifications);
//   const [selectedType, setSelectedType] = useState(null);
//   const [selectedStatus, setSelectedStatus] = useState("unread");
//   const [dateFilter, setDateFilter] = useState("today");

//   console.log("allNotification", allNotification);

//   // Extract unique notification types
//   const notificationTypes = useMemo(() => {
//     if (!allNotification || !allNotification.data) return [];
//     const types = allNotification.data.map((notification) => notification.type);
//     return [...new Set(types)];
//   }, [allNotification]);

//   // Filter notifications based on selected type, status, and date filter
//   const filteredNotifications = useMemo(() => {
//     if (!allNotification || !allNotification.data) return [];
//     const today = moment().startOf("day");

//     const filtered = allNotification.data.filter((notification) => {
//       const matchesType = selectedType
//         ? notification.type === selectedType
//         : true;
//       const matchesStatus = selectedStatus
//         ? (notification.read_at === null && selectedStatus === "unread") ||
//           (notification.read_at !== null && selectedStatus === "read")
//         : true;
//       return matchesType && matchesStatus;
//     });

//     const sortedNotifications = filtered.slice().sort((a, b) => {
//       if (dateFilter === "latest") {
//         return moment(b.created_at) - moment(a.created_at);
//       } else if (dateFilter === "oldest") {
//         return moment(a.created_at) - moment(b.created_at);
//       }
//       return 0;
//     });

//     if (dateFilter === "today") {
//       const todayNotifications = sortedNotifications.filter((notification) =>
//         moment(notification.created_at).isSame(today, "day")
//       );
//       const otherNotifications = sortedNotifications.filter(
//         (notification) => !moment(notification.created_at).isSame(today, "day")
//       );
//       return [...todayNotifications, ...otherNotifications];
//     }

//     return sortedNotifications;
//   }, [selectedType, selectedStatus, dateFilter, allNotification]);

//   const columns = [
//     {
//       title: <div>Notification Type</div>,
//       width: "30%",
//       dataIndex: "type",
//       key: "type",
//       render: (type) => (
//         <div>
//           <Tag color="#1c1413" style={{ fontSize: 13, fontWeight: "bold" }}>
//             {type.split("\\").pop()}
//           </Tag>
//         </div>
//       ),
//     },
//     {
//       title: <div style={{ textAlign: "center" }}>Trigger User</div>,
//       width: "30%",
//       dataIndex: "data",
//       key: "trigger_user",
//       render: (data) => {
//         const notificationData = JSON.parse(data);
//         const triggerUser =
//           notificationData.student ||
//           notificationData.instructor ||
//           notificationData.reservation ||
//           {};
//         return (
//           <span style={{ display: "block", fontSize: 13 }}>
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <div className="flex">
//                 <div>
//                   <img
//                     src={
//                       triggerUser?.profile_picture ||
//                       triggerUser.instructor?.profile_picture ||
//                       ""
//                     }
//                     alt=""
//                     style={{
//                       borderRadius: "50%",
//                       height: "38px",
//                       width: "38px",
//                       marginRight: "10px",
//                     }}
//                   />
//                 </div>
//                 <div>
//                   <h6
//                     style={{
//                       fontSize: "13px",
//                       textAlign: "left",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {triggerUser?.name?.split("+").join(" ") ||
//                       triggerUser.instructor?.name.split("+").join(" ")}
//                   </h6>
//                   <div
//                     style={{
//                       fontSize: 11,
//                       marginLeft: "1px",
//                       color: "#808080",
//                       fontWeight: "normal",
//                     }}
//                   >
//                     {triggerUser?.email || triggerUser.instructor?.email}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </span>
//         );
//       },
//     },
//     {
//       title: <div style={{ textAlign: "center" }}>Status</div>,
//       width: "30%",
//       dataIndex: "data",
//       key: "read_status",
//       render: (data, record) => {
//         const isUnread = record.read_at === null;
//         return (
//           <div style={{ textAlign: "center" }}>
//             <Tag
//               color={isUnread ? "#f50" : ""}
//               style={{ fontSize: 13, fontWeight: "bold" }}
//             >
//               {isUnread ? "unread" : "read"}
//             </Tag>
//           </div>
//         );
//       },
//     },
//   ];

//   const expandedRowRender = (record) => {
//     const notificationData = JSON.parse(record.data);
//     console.log("notificationData", notificationData);
//     return (
//       <div
//         style={{
//           padding: "16px",
//           backgroundColor: "#bde0fe",
//           borderRadius: "8px",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           gap: "5px",
//         }}
//       >
//         <div
//           style={{
//             width: "30%",
//             textAlign: "center",
//           }}
//         >
//           <h4 style={{ marginBottom: "5px", fontWeight: "bold", fontSize: 14 }}>
//             Notification Content
//           </h4>

//           <p
//             style={{
//               marginBottom: "0",
//               fontSize: "14px",
//               color: "#595959",
//               fontWeight: "normal",
//             }}
//           >
//             {notificationData?.message}
//           </p>
//         </div>
//         <div
//           style={{
//             width: "30%",
//             textAlign: "center",
//           }}
//         >
//           <h4 style={{ marginBottom: "5px", fontWeight: "bold", fontSize: 14 }}>
//             Created At
//           </h4>
//           <p
//             style={{
//               marginBottom: "0",
//               fontSize: "14px",
//               color: "#595959",
//               fontWeight: "normal",
//             }}
//           >
//             {monthDayAndYear(record.created_at)} - {onlyTime(record.created_at)}
//           </p>
//         </div>
//         <div
//           style={{
//             width: "30%",
//             textAlign: "center",
//           }}
//         >
//           <h4 style={{ marginBottom: "5px", fontWeight: "bold", fontSize: 14 }}>
//             Updated At
//           </h4>
//           <p
//             style={{
//               marginBottom: "0",
//               fontSize: "14px",
//               color: "#595959",
//               fontWeight: "normal",
//             }}
//           >
//             {monthDayAndYear(record.updated_at)} - {onlyTime(record.updated_at)}
//           </p>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div>
//       <Select
//         placeholder="Select notification type"
//         style={{ width: 200, marginBottom: 16, marginTop: 48 }}
//         onChange={(value) => setSelectedType(value)}
//         allowClear
//       >
//         {notificationTypes.map((type) => (
//           <Option key={type} value={type}>
//             {type.split("\\").pop()}
//           </Option>
//         ))}
//       </Select>
//       <Select
//         placeholder="Select read status"
//         style={{ width: 200, marginBottom: 16, marginTop: 16, marginLeft: 16 }}
//         onChange={(value) => setSelectedStatus(value)}
//         allowClear
//         value={selectedStatus}
//       >
//         <Option value="unread">Unread</Option>
//         <Option value="read">Read</Option>
//       </Select>
//       <Select
//         placeholder="Select date filter"
//         style={{ width: 200, marginBottom: 16, marginTop: 16, marginLeft: 16 }}
//         onChange={(value) => setDateFilter(value)}
//         value={dateFilter}
//       >
//         <Option value="today">Today</Option>
//         <Option value="latest">Latest</Option>
//         <Option value="oldest">Oldest</Option>
//       </Select>
//       <Table
//         columns={columns}
//         dataSource={filteredNotifications}
//         rowKey="id"
//         expandable={{ expandedRowRender }}
//         style={{ fontSize: 13, fontWeight: "bold" }}
//         scroll={{ x: true }}
//       />
//     </div>
//   );
// }

// export default AllNotification;
// ----------------------------------------------------------------
// ----------------------------------------------------------------

// for pagination I copies the upper coder
// import { useState, useMemo, useEffect } from "react";
// import { useSelector } from "react-redux";
// import { Table, Tag, Select } from "antd";

// import { monthDayAndYear, onlyTime } from "../../Util/helperFunctions";
// import moment from "moment";
// import { useThunk } from "../../hooks/useThunk";
// import { getAllNotification } from "../../slices/thunk";

// const { Option } = Select;

// function AllNotification() {
//   const { allNotification } = useSelector((state) => state.Notifications);
//   const [selectedType, setSelectedType] = useState(null);
//   const [selectedStatus, setSelectedStatus] = useState("unread");
//   const [dateFilter, setDateFilter] = useState("today");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [perPage, setPerPage] = useState(5);

//   const [getNotification] = useThunk(getAllNotification);
//   console.log("allNotification", allNotification);

//   useEffect(() => {
//     getNotification({ page: currentPage, perPage: perPage });
//   }, [getNotification, currentPage, perPage]);

//   // Extract unique notification types
//   const notificationTypes = useMemo(() => {
//     if (!allNotification || !allNotification.data) return [];
//     const types = allNotification.data.map((notification) => notification.type);
//     return [...new Set(types)];
//   }, [allNotification]);

//   // Filter notifications based on selected type, status, and date filter
//   const filteredNotifications = useMemo(() => {
//     if (!allNotification || !allNotification.data) return [];
//     const today = moment().startOf("day");

//     const filtered = allNotification.data.filter((notification) => {
//       const matchesType = selectedType
//         ? notification.type === selectedType
//         : true;
//       const matchesStatus = selectedStatus
//         ? (notification.read_at === null && selectedStatus === "unread") ||
//           (notification.read_at !== null && selectedStatus === "read")
//         : true;
//       return matchesType && matchesStatus;
//     });

//     const sortedNotifications = filtered.slice().sort((a, b) => {
//       if (dateFilter === "latest") {
//         return moment(b.created_at) - moment(a.created_at);
//       } else if (dateFilter === "oldest") {
//         return moment(a.created_at) - moment(b.created_at);
//       }
//       return 0;
//     });

//     if (dateFilter === "today") {
//       const todayNotifications = sortedNotifications.filter((notification) =>
//         moment(notification.created_at).isSame(today, "day")
//       );
//       const otherNotifications = sortedNotifications.filter(
//         (notification) => !moment(notification.created_at).isSame(today, "day")
//       );
//       return [...todayNotifications, ...otherNotifications];
//     }

//     return sortedNotifications;
//   }, [selectedType, selectedStatus, dateFilter, allNotification]);

//   const columns = [
//     {
//       title: <div>Notification Type</div>,
//       width: "30%",
//       dataIndex: "type",
//       key: "type",
//       render: (type) => (
//         <div>
//           <Tag color="#1c1413" style={{ fontSize: 13, fontWeight: "bold" }}>
//             {type.split("\\").pop()}
//           </Tag>
//         </div>
//       ),
//     },
//     {
//       title: <div style={{ textAlign: "center" }}>Trigger User</div>,
//       width: "30%",
//       dataIndex: "data",
//       key: "trigger_user",
//       render: (data) => {
//         const notificationData = JSON.parse(data);
//         const triggerUser =
//           notificationData.student ||
//           notificationData.instructor ||
//           notificationData.reservation ||
//           {};
//         return (
//           <span style={{ display: "block", fontSize: 13 }}>
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <div className="flex">
//                 <div>
//                   <img
//                     src={
//                       triggerUser?.profile_picture ||
//                       triggerUser.instructor?.profile_picture ||
//                       ""
//                     }
//                     alt=""
//                     style={{
//                       borderRadius: "50%",
//                       height: "38px",
//                       width: "38px",
//                       marginRight: "10px",
//                     }}
//                   />
//                 </div>
//                 <div>
//                   <h6
//                     style={{
//                       fontSize: "13px",
//                       textAlign: "left",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {triggerUser?.name?.split("+").join(" ") ||
//                       triggerUser.instructor?.name.split("+").join(" ")}
//                   </h6>
//                   <div
//                     style={{
//                       fontSize: 11,
//                       marginLeft: "1px",
//                       color: "#808080",
//                       fontWeight: "normal",
//                     }}
//                   >
//                     {triggerUser?.email || triggerUser.instructor?.email}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </span>
//         );
//       },
//     },
//     {
//       title: <div style={{ textAlign: "center" }}>Status</div>,
//       width: "30%",
//       dataIndex: "data",
//       key: "read_status",
//       render: (data, record) => {
//         const isUnread = record.read_at === null;
//         return (
//           <div style={{ textAlign: "center" }}>
//             <Tag
//               color={isUnread ? "#f50" : ""}
//               style={{ fontSize: 13, fontWeight: "bold" }}
//             >
//               {isUnread ? "unread" : "read"}
//             </Tag>
//           </div>
//         );
//       },
//     },
//   ];

//   const expandedRowRender = (record) => {
//     const notificationData = JSON.parse(record.data);
//     console.log("notificationData", notificationData);
//     return (
//       <div
//         style={{
//           padding: "16px",
//           backgroundColor: "#bde0fe",
//           borderRadius: "8px",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           gap: "5px",
//         }}
//       >
//         <div
//           style={{
//             width: "30%",
//             textAlign: "center",
//           }}
//         >
//           <h4 style={{ marginBottom: "5px", fontWeight: "bold", fontSize: 14 }}>
//             Notification Content
//           </h4>

//           <p
//             style={{
//               marginBottom: "0",
//               fontSize: "14px",
//               color: "#595959",
//               fontWeight: "normal",
//             }}
//           >
//             {notificationData?.message}
//           </p>
//         </div>
//         <div
//           style={{
//             width: "30%",
//             textAlign: "center",
//           }}
//         >
//           <h4 style={{ marginBottom: "5px", fontWeight: "bold", fontSize: 14 }}>
//             Created At
//           </h4>
//           <p
//             style={{
//               marginBottom: "0",
//               fontSize: "14px",
//               color: "#595959",
//               fontWeight: "normal",
//             }}
//           >
//             {monthDayAndYear(record.created_at)} - {onlyTime(record.created_at)}
//           </p>
//         </div>
//         <div
//           style={{
//             width: "30%",
//             textAlign: "center",
//           }}
//         >
//           <h4 style={{ marginBottom: "5px", fontWeight: "bold", fontSize: 14 }}>
//             Updated At
//           </h4>
//           <p
//             style={{
//               marginBottom: "0",
//               fontSize: "14px",
//               color: "#595959",
//               fontWeight: "normal",
//             }}
//           >
//             {monthDayAndYear(record.updated_at)} - {onlyTime(record.updated_at)}
//           </p>
//         </div>
//       </div>
//     );
//   };

//   const handleTableChange = (pagination) => {
//     setCurrentPage(pagination.current);
//     setPerPage(pagination.pageSize);
//   };

//   return (
//     <div>
//       <Select
//         placeholder="Select notification type"
//         style={{ width: 200, marginBottom: 16, marginTop: 48 }}
//         onChange={(value) => setSelectedType(value)}
//         allowClear
//       >
//         {notificationTypes.map((type) => (
//           <Option key={type} value={type}>
//             {type.split("\\").pop()}
//           </Option>
//         ))}
//       </Select>
//       <Select
//         placeholder="Select read status"
//         style={{ width: 200, marginBottom: 16, marginTop: 16, marginLeft: 16 }}
//         onChange={(value) => setSelectedStatus(value)}
//         allowClear
//         value={selectedStatus}
//       >
//         <Option value="unread">Unread</Option>
//         <Option value="read">Read</Option>
//       </Select>
//       <Select
//         placeholder="Select date filter"
//         style={{ width: 200, marginBottom: 16, marginTop: 16, marginLeft: 16 }}
//         onChange={(value) => setDateFilter(value)}
//         value={dateFilter}
//       >
//         <Option value="today">Today</Option>
//         <Option value="latest">Latest</Option>
//         <Option value="oldest">Oldest</Option>
//       </Select>
//       <Table
//         columns={columns}
//         dataSource={filteredNotifications}
//         rowKey="id"
//         expandable={{ expandedRowRender }}
//         style={{ fontSize: 13, fontWeight: "bold" }}
//         scroll={{ x: true }}
//         pagination={{
//           current: currentPage,
//           pageSize: perPage,
//           total: allNotification.total,
//           showSizeChanger: true,
//           pageSizeOptions: ["5", "10", "20", "50"],
//         }}
//         onChange={handleTableChange}
//       />
//     </div>
//   );
// }

// export default AllNotification;

// ----------------------------------------------------------------
// ----------------------------------------------------------------

//final working codes
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Tag, Select } from "antd";

import { monthDayAndYear, onlyTime } from "../../Util/helperFunctions";
import moment from "moment";
import { useThunk } from "../../hooks/useThunk";
import { getAllNotification } from "../../slices/thunk";

const { Option } = Select;

function AllNotification() {
  const { allNotification } = useSelector((state) => state.Notifications);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("unread");
  const [dateFilter, setDateFilter] = useState("today");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [getNotification] = useThunk(getAllNotification);
  console.log("allNotification", allNotification);

  useEffect(() => {
    getNotification({ page: currentPage, perPage: perPage });
  }, [getNotification, currentPage, perPage]);

  // Extract unique notification types
  const notificationTypes = useMemo(() => {
    if (!allNotification || !allNotification.data) return [];
    const types = allNotification.data.map((notification) => notification.type);
    return [...new Set(types)];
  }, [allNotification]);

  // Filter notifications based on selected type, status, and date filter
  const filteredNotifications = useMemo(() => {
    if (!allNotification || !allNotification.data) return [];
    const today = moment().startOf("day");

    const filtered = allNotification.data.filter((notification) => {
      const matchesType = selectedType
        ? notification.type === selectedType
        : true;
      const matchesStatus = selectedStatus
        ? (notification.read_at === null && selectedStatus === "unread") ||
          (notification.read_at !== null && selectedStatus === "read")
        : true;
      return matchesType && matchesStatus;
    });

    const sortedNotifications = filtered.slice().sort((a, b) => {
      if (dateFilter === "latest") {
        return moment(b.created_at) - moment(a.created_at);
      } else if (dateFilter === "oldest") {
        return moment(a.created_at) - moment(b.created_at);
      }
      return 0;
    });

    if (dateFilter === "today") {
      const todayNotifications = sortedNotifications.filter((notification) =>
        moment(notification.created_at).isSame(today, "day")
      );
      const otherNotifications = sortedNotifications.filter(
        (notification) => !moment(notification.created_at).isSame(today, "day")
      );
      return [...todayNotifications, ...otherNotifications];
    }

    return sortedNotifications;
  }, [selectedType, selectedStatus, dateFilter, allNotification]);

  const columns = [
    {
      title: <div>Notification Type</div>,
      width: "30%",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <div>
          <Tag color="#1c1413" style={{ fontSize: 13, fontWeight: "bold" }}>
            {type.split("\\").pop()}
          </Tag>
        </div>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Trigger User</div>,
      width: "30%",
      dataIndex: "data",
      key: "trigger_user",
      render: (data) => {
        const notificationData = JSON.parse(data);
        const triggerUser =
          notificationData.student ||
          notificationData.instructor ||
          notificationData.reservation ||
          {};
        return (
          <span style={{ display: "block", fontSize: 13 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="flex">
                <div>
                  <img
                    src={
                      triggerUser?.profile_picture ||
                      triggerUser.instructor?.profile_picture ||
                      ""
                    }
                    alt=""
                    style={{
                      borderRadius: "50%",
                      height: "38px",
                      width: "38px",
                      marginRight: "10px",
                    }}
                  />
                </div>
                <div>
                  <h6
                    style={{
                      fontSize: "13px",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {triggerUser?.name?.split("+").join(" ") ||
                      triggerUser.instructor?.name.split("+").join(" ")}
                  </h6>
                  <div
                    style={{
                      fontSize: 11,
                      marginLeft: "1px",
                      color: "#808080",
                      fontWeight: "normal",
                    }}
                  >
                    {triggerUser?.email || triggerUser.instructor?.email}
                  </div>
                </div>
              </div>
            </div>
          </span>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Status</div>,
      width: "30%",
      dataIndex: "data",
      key: "read_status",
      render: (data, record) => {
        const isUnread = record.read_at === null;
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={isUnread ? "#f50" : ""}
              style={{ fontSize: 13, fontWeight: "bold" }}
            >
              {isUnread ? "unread" : "read"}
            </Tag>
          </div>
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    const notificationData = JSON.parse(record.data);
    console.log("notificationData", notificationData);
    return (
      <div
        style={{
          padding: "16px",
          backgroundColor: "#bde0fe",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <div
          style={{
            width: "30%",
            textAlign: "center",
          }}
        >
          <h4 style={{ marginBottom: "5px", fontWeight: "bold", fontSize: 14 }}>
            Notification Content
          </h4>

          <p
            style={{
              marginBottom: "0",
              fontSize: "14px",
              color: "#595959",
              fontWeight: "normal",
            }}
          >
            {notificationData?.message}
          </p>
        </div>
        <div
          style={{
            width: "30%",
            textAlign: "center",
          }}
        >
          <h4 style={{ marginBottom: "5px", fontWeight: "bold", fontSize: 14 }}>
            Created At
          </h4>
          <p
            style={{
              marginBottom: "0",
              fontSize: "14px",
              color: "#595959",
              fontWeight: "normal",
            }}
          >
            {monthDayAndYear(record.created_at)} - {onlyTime(record.created_at)}
          </p>
        </div>
        <div
          style={{
            width: "30%",
            textAlign: "center",
          }}
        >
          <h4 style={{ marginBottom: "5px", fontWeight: "bold", fontSize: 14 }}>
            Updated At
          </h4>
          <p
            style={{
              marginBottom: "0",
              fontSize: "14px",
              color: "#595959",
              fontWeight: "normal",
            }}
          >
            {monthDayAndYear(record.updated_at)} - {onlyTime(record.updated_at)}
          </p>
        </div>
      </div>
    );
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPerPage(pagination.pageSize);
  };

  return (
    <div>
      <Select
        placeholder="Select notification type"
        style={{ width: 200, marginBottom: 16, marginTop: 48 }}
        onChange={(value) => setSelectedType(value)}
        allowClear
      >
        {notificationTypes.map((type) => (
          <Option key={type} value={type}>
            {type.split("\\").pop()}
          </Option>
        ))}
      </Select>
      <Select
        placeholder="Select read status"
        style={{ width: 200, marginBottom: 16, marginTop: 16, marginLeft: 16 }}
        onChange={(value) => setSelectedStatus(value)}
        allowClear
        value={selectedStatus}
      >
        <Option value="unread">Unread</Option>
        <Option value="read">Read</Option>
      </Select>
      <Select
        placeholder="Select date filter"
        style={{ width: 200, marginBottom: 16, marginTop: 16, marginLeft: 16 }}
        onChange={(value) => setDateFilter(value)}
        value={dateFilter}
      >
        <Option value="today">Today</Option>
        <Option value="latest">Latest</Option>
        <Option value="oldest">Oldest</Option>
      </Select>
      <Table
        columns={columns}
        dataSource={filteredNotifications}
        rowKey="id"
        expandable={{ expandedRowRender }}
        style={{ fontSize: 13, fontWeight: "bold" }}
        scroll={{ x: true }}
        pagination={{
          current: currentPage,
          pageSize: perPage,
          total: allNotification.total,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50"],
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}

export default AllNotification;
