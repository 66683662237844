import { createSlice } from "@reduxjs/toolkit";
import { getAllNotification, getAllNotificationForScroll } from "./thunk";

export const initialState = {
  allNotification: [],
  error: null,
  loading: false,
  allNotificationForScroll: [],
  allNotificationForScrollData: [],
};

const notification = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get all notifications
    builder.addCase(getAllNotification.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.allNotification = action.payload;
    });
    builder.addCase(getAllNotification.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    //get all notifications for Scroll
    builder.addCase(getAllNotificationForScroll.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllNotificationForScroll.fulfilled, (state, action) => {
      state.loading = false;
      state.allNotificationForScroll = action.payload;

      if (state.allNotificationForScrollData.length === 0) {
        state.allNotificationForScrollData = action.payload.data;
      } else {
        state.allNotificationForScrollData = [
          ...state.allNotificationForScrollData,
          ...action.payload.data,
        ];
      }
    });
    builder.addCase(getAllNotificationForScroll.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default notification.reducer;
